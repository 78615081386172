@import '../src/Components/header.css';
body {
  min-height: 200vh;
  background-color: #000f14;
  margin: 0;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 8px solid #ccc;
  position: relative;
  animation: spin 2s linear infinite;
}

.loader-progress {
  position: absolute;
  width: 0;
  height: 100%;
  background-color: #4caf50;
  border-radius: 50%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader-text {
  margin-top: 20px;
  font-size: 18px;
}

#content {
  display: none;
}

.Aakriti-logo{
  /* filter: drop-shadow(0px 0px 3px #7aefff);  */
}
#div{
  position: relative;
  z-index: 10;
}
#globe-render {
  position: absolute;
  top: 0%;
  right: 100%;
  background-color: rgb(255, 255, 255);
}
#myCanvas {
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 128, 0, 0);
}
canvas {
  background: transparent;
  z-index: -1;

}

.Skewed a{
  box-shadow: 0px 0px 5px #ffa2cd,0px 0px 10px #ffa2cd;
}
.Skewed {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}