@import './header.css';


.icon-img{
    max-width: 30px;
}
.Festival{
    text-shadow: 0px 0px 20px #ffffff;
}
.verti-bar-2-start{
    box-shadow:0px 0px 5px #ff1e87 ;
}
.unleash{
    text-shadow: 0px 0px 15px #ff3996;
}
.WhyAak-matter{
    box-shadow:0px 0px 5px #ff56a5,0px 0px 15px #af0052,0px 0px 15px #000000,0px 0px 20px #000000,0px 0px 25px #000000,0px 0px 30px #000000;
}
.verti-bar-3-start{
    box-shadow:0px 0px 5px #39FF14 ;
}
.verti-bar-4-start{
  box-shadow:0px 0px 5px #FFFF33 ;
}
.verti-bar-2-end {
    @apply rounded w-3px md:w-5px h-full bg-gradient-to-b from-[#ff1e87] to-[#39FF14];
  }
  
  .verti-bar-2-end::before {
    @apply absolute w-5px md:w-5px h-full bg-gradient-to-b from-[#ff1e87] to-[#39FF14] rounded-lg shadow-sm;
    content: '';
    filter: blur(5px);
  }
  .verti-bar-3-end {
    @apply rounded w-3px md:w-5px h-full bg-gradient-to-b from-[#39FF14] to-[#FFFF33];
  }
  .verti-bar-4-end {
    box-shadow:0px 0px 5px #FFFF33
    }
  .verti-bar-3-end::before {
    @apply absolute w-5px md:w-5px h-full bg-gradient-to-b from-[#39FF14] to-[#FFFF33] rounded-lg shadow-sm;
    content: '';
    filter: blur(5px);
  }
  .verti-bar-4-start::before {
    @apply absolute w-5px md:w-5px h-full bg-gradient-to-b from-[#FFFF33] to-[#FFFF33] rounded-lg shadow-sm;
    content: '';
    filter: blur(5px);
  }
  .verti-bar-4-end::before {
    @apply absolute w-5px md:w-5px h-full bg-gradient-to-b from-[#FFFF33] to-[#FFFF33] rounded-lg shadow-sm;
    content: '';
    filter: blur(5px);
  }

.timeline{
    text-shadow: 0px 0px 15px #39FF14;
}
.timeline-title h2{
    writing-mode:vertical-rl;
    text-orientation: mixed; 
    white-space: nowrap;
}
.timeline-matter{
    box-shadow:0px 0px 10px #5ff744,0px 0px 15px #000000,0px 0px 20px #000000,0px 0px 25px #000000,0px 0px 30px #000000;
}
.timeline-bar-1-2{
    @apply bg-gradient-to-r from-[#2ddfff] to-[#ff1e87];
  }
  
  .timeline-bar-1-2::before {
    @apply absolute h-5px w-56 bg-gradient-to-r from-[#2ddfff] to-[#ff1e87] rounded-lg shadow-sm;
    content: '';
    filter: blur(5px);
  }

  .timeline-bar-2-3{
    @apply bg-gradient-to-r from-[#ff1e87] to-[#5ff744];
  }
  
  .timeline-bar-2-3::before {
    @apply absolute h-5px w-56 bg-gradient-to-r from-[#ff1e87] to-[#5ff744] rounded-lg shadow-sm;
    content: '';
    filter: blur(5px);
  }

  .timeline-bar-3-4{
    @apply bg-gradient-to-r from-[#5ff744] to-[#FFFF33];
  }
  
  .timeline-bar-3-4::before {
    @apply absolute h-5px w-56 bg-gradient-to-r from-[#5ff744] to-[#FFFF33] rounded-lg shadow-sm;
    content: '';
    filter: blur(5px);
  }


  .icon-img{
    max-width: 30px;
}
.Festival{
    text-shadow: 0px 0px 20px #ffffff;
}
.verti-bar-4-start{
    box-shadow:0px 0px 5px #FFFF33 ;
}
.Creativity{
    text-shadow: 0px 0px 15px #FFFF33;
}
.events-matter{
    box-shadow:0px 0px 5px #ffff52,0px 0px 15px #afa900,0px 0px 15px #000000,0px 0px 20px #000000,0px 0px 25px #000000,0px 0px 30px #000000;
}
.verti-bar-4-end {
    @apply rounded w-3px md:w-5px h-full bg-gradient-to-b from-[#FFFF33] to-[#FFFF33];
  }
  .verti-bar-4-end::before {
    @apply absolute  w-3px md:w-5px h-full bg-gradient-to-r from-[#FFFF33] to-[#FFFF33] rounded-lg shadow-sm;
    content: '';
    filter: blur(5px);
  }

  @media screen and (max-width:760px){
    .timeline-title h2{
      writing-mode:horizontal-tb;
      text-orientation:sideways; 
      white-space: nowrap;
    }
    .timeline-bar-1-2{
      @apply bg-gradient-to-b from-[#2ddfff] to-[#ff1e87];
    }
    
    .timeline-bar-1-2::before {
      @apply absolute h-56 md:h-5px w-5px md:w-56  bg-gradient-to-r from-[#2ddfff] to-[#ff1e87] rounded-lg shadow-sm;
      content: '';
      filter: blur(5px);
    }
  
    .timeline-bar-2-3{
      @apply bg-gradient-to-b from-[#ff1e87] to-[#5ff744];
    }
    
    .timeline-bar-2-3::before {
      @apply absolute h-56 md:h-5px w-5px md:w-56 bg-gradient-to-b from-[#ff1e87] to-[#5ff744] rounded-lg shadow-sm;
      content: '';
      filter: blur(5px);
    }
  
    .timeline-bar-3-4{
      @apply bg-gradient-to-b from-[#5ff744] to-[#FFFF33];
    }
    
    .timeline-bar-3-4::before {
      @apply absolute h-56 md:h-5px w-5px md:w-56 bg-gradient-to-b from-[#5ff744] to-[#FFFF33] rounded-lg shadow-sm;
      content: '';
      filter: blur(5px);
    }
  
    
  }

  .circle1{
    box-shadow: 0px 0px 10px #2ddfff;
  }
  .circle2{
    box-shadow: 0px 0px 10px #ff1e87;
  }
  .circle3{
    box-shadow: 0px 0px 10px #39FF14;
  }
  .circle4{
    box-shadow: 0px 0px 10px #FFFF33;
  }


  .background-animate {
    background-size: 400%;

    -webkit-animation: AnimationName 10s ease infinite;
    -moz-animation: AnimationName 10s ease infinite;
    animation: AnimationName 10s ease infinite;
  }

  @keyframes AnimationName {
    0%,
    100% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
  }
  .insta-card{
  }
  .insta-card::before{
  content: '';
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #f51371 ,#a81df4, #fc811c);
  background-size: 400%;
  -webkit-animation: AnimationName 10s ease infinite;
  -moz-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite;
  filter: blur(100px);
}









