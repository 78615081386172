
.button1{
    box-shadow: 0px 1px 10px  rgb(255, 150, 97);
  }
  
.button2{
    box-shadow: 0px 0px 10px  rgb(97, 192, 255);
  }
  .techart-matter{
    box-shadow: 0px 1px 10px  rgb(255, 150, 97);
  }